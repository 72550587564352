const { isPrintMode } = usePrint();
export const BREAKPOINTS = {
    /* eslint-disable sort-keys-fix/sort-keys-fix */
    '2xl': 1920,
    xl: 1440,
    lg: 1024,
    md: 768,
    sm: 576,
    /* eslint-enable sort-keys-fix/sort-keys-fix */
};

function useMediaQuery(query: string): Ref<boolean> {
    if (!isClient) return ref(false);
    if (!window.matchMedia) return ref(false);

    const mediaQuery = window?.matchMedia(query);
    const matches: Ref<boolean> = ref(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => {
        matches.value = event.matches;
        if (isPrintMode.value) matches.value = true;
        if (isPrintMode.value && query.includes('max')) matches.value = false;
    };

    mediaQuery?.addEventListener('change', handler);

    if (getCurrentInstance()) {
        onUnmounted(() => {
            mediaQuery?.removeEventListener('change', handler);
        });
    }

    return matches;
}

// TODO: mode has to be set to 'min' without exception!
export function useBreakpoint(breakpoint: number, mode: 'min' | 'max' = 'min') {
    let breakpointStr = '';
    if (mode === 'max') {
        breakpointStr = `${breakpoint - 1}px`;
    } else {
        breakpointStr = `${breakpoint}px`;
    }
    return useMediaQuery(`(${mode}-width: ${breakpointStr})`);
}
