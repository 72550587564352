const { t } = useLang();

const isPrintMode = ref(false);
const ORIGINAL_APP_TITLE = `${t('page', 'search')} | ${t('page', 'platform')}`;

export const usePrint = () => {
    const profileId = ref();

    const changeDocumentTitle = (title: string) => {
        document.title = title;
    };

    const changePrintMode = (mode: boolean) => {
        isPrintMode.value = mode;
    };

    const updateProfileId = (value: number | undefined) => {
        profileId.value = value;
    };

    const onBeforePrint = () => {
        changePrintMode(true);
        changeDocumentTitle(`ProfileID_${profileId.value}`);
    };

    const onAfterPrint = () => {
        changePrintMode(false);
        changeDocumentTitle(ORIGINAL_APP_TITLE);
    };

    const addPrintEvents = () => {
        window?.addEventListener('beforeprint', onBeforePrint);
        window?.addEventListener('afterprint', onAfterPrint);
    };

    const removePrintEvents = () => {
        window?.removeEventListener('beforeprint', onBeforePrint);
        window?.removeEventListener('afterprint', onAfterPrint);
    };

    const openPrintDialog = () => {
        changePrintMode(true);
        setTimeout(() => window.print(), 0);
    };

    return {
        addPrintEvents,
        changePrintMode,
        isPrintMode,
        openPrintDialog,
        removePrintEvents,
        updateProfileId,
    };
};
